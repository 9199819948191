<template>
    <div class="row">
        <div class="col-4" v-for="item in items" :key="item">
            <div
                :class="['head-item', 'text-center', 'cursor-pointer', {'active': isActive(item.key)}]"
                @click="switchSection(item.key)"
            >
                <div>
                    <component :is="item.icon" width="32" :color="getIconColor(item.key)" />
                </div>
                <div class="item-title">{{ item.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CardAddIcon from "@/components/Icon/CardAddIcon";
import CrownIcon from "@/components/Icon/CrownIcon";
import FireIcon from "@/components/Icon/FireIcon";
import {mapGetters} from "vuex";

export default {
    name: "CatalogHead",
    components: {
        CardAddIcon, CrownIcon, FireIcon
    },
    data() {
        return {
            items: [
                { key: 'sum', text: 'Сертификат на сумму', icon: 'CardAddIcon' },
                { key: 'service', text: 'Сертификат на услугу', icon: 'CrownIcon' },
                { key: 'sale', text: 'Акции для Вас', icon: 'FireIcon' },
            ]
        }
    },
    computed: {
        ...mapGetters({
            getSection: 'catalog/section'
        })
    },
    methods: {
        /**
         * Check section to be active by section key
         * @param {String} section
         * @return boolean
         */
        isActive(section) { return section === this.getSection; },

        /**
         * Get icon color by active status
         * @param {String} section
         * @return String
         */
        getIconColor(section) { return this.isActive(section) ? 'white' : 'black'; },

        /**
         * Set active section
         * @param {String} section
         * @return void
         */
        switchSection(section) { this.$store.dispatch('catalog/setSection', section); }
    }
}
</script>

<style scoped lang="scss">
.row>.col-4:nth-child(1) { padding-right: 11px; }

.row>.col-4:nth-child(2) { padding: 0 5px; }

.row>.col-4:nth-child(3) { padding-left: 11px; }

.head-item {
    padding: 16px 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.head-item.active {
    background-color: $primary-color;
    color: white;
}

.item-title {
    padding: 0 15px;
    margin-top: 6px;
}
.col-4:nth-child(3) .item-title { padding: 0 27px; }
</style>