import axios from "axios";

function defaultState() {
    return {
        apiUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/get-region-facilities',
        facilities: false,
        facilitiesTimesArr: {
            30: '30 мин',
            45: '45 мин',
            60: '1 час',
            75: '1 час 15 мин',
            90: '1,5 часа',
            120: '2 часа',
            150: '2,5 часа',
            180: '3 часа'
        },
        moneyCertificateTitle: 'Сертификат на сумму',
        moneyCertificatesTypes: [ 0.5, 0.75, 1, 1.5, 2 ],
        customCertificates: {}
    };
}

/**
 * Модуль для услуг с данными
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },
        facilities(state) { return state.facilities; },

        /**
         * Get custom certificates array
         * @param {Object} state
         * @return Array
         */
        customCertificates(state) { return Object.values(state.customCertificates).reverse(); },
        moneyCertificates(state, getters, rootState, rootGetters) {
            const region = rootGetters["data/region"];
            const cost = rootGetters["regions/regionCost"](region);
            return getters.customCertificates.concat(state.moneyCertificatesTypes.map(type => { return { id: type, price: cost * type }; }));
        },

        /**
         * Get facilities times array
         * @param {Object} state
         * @return Object
         */
        facilitiesTimesArr(state) { return state.facilitiesTimesArr; },

        /**
         * Get facility by id
         * @param {Object} state
         * @return function
         */
        getFacilityById(state) {
            return function(facilityId) {
                for (let index = 0; index < state.facilities.length; index++) {
                    if (facilityId === state.facilities[index].id) {
                        return state.facilities[index];
                    }
                }
                return false;
            };
        },

        /**
         * Get money certifiacte title
         * @param {Object} state
         * return String
         */
        moneyCertificateTitle(state) { return state.moneyCertificateTitle; }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Fetch api and set facilities array
         * @param {Object} context
         * @param {String} regionId
         */
        async set(context, regionId) {
            let response = await axios.get(context.state.apiUrl, {
                params: { per_page: -1, region: regionId },
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            if (response.success) {
                const facilities = response.data.filter(f => f.prices.length).map(f => {
                    let result = f;
                    let timeIds = {};
                    result.price = result.prices.filter(price => price.duration)
                        .map(price => {
                            timeIds[price.duration] = 0;
                            return {
                                price: price.price.val,
                                time: context.state.facilitiesTimesArr[price.duration],
                                id: price.duration // time int
                            };
                        })
                        .sort((a, b) => {return a.price > b.price;})
                        .filter(price => !(timeIds[price.id]++)); // Убираем повторяющиеся цены
                    return result;
                }).filter(f => f.name !== 'Кедровая бочка');
                console.log(facilities);
                context.commit('setFacilities', facilities);
            }
            return response.success;
        },

        /**
         * Set custom sum certificate
         * @param {Object} context
         * @param {Number} price
         */
        setCustomCertificate(context, price) { context.commit('setCustomCertificate', price); },

        /**
         * Delete custom sum certificate
         * @param {Object} context
         * @param {Number} price
         */
        deleteCustomCertificate(context, price) { context.commit('deleteCustomCertificate', price); }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        setFacilities(state, facilitiesData) { state.facilities = facilitiesData; },
        setCustomCertificate(state, price) {
            state.customCertificates[price] = {
                id: price,
                price: price
            };
        },

        /**
         * Delete custom sum certificate
         * @param {Object} state
         * @param {Number} price
         */
        deleteCustomCertificate(state, price) {
            if (Object.keys(state.customCertificates).includes(price)) {
                delete state.customCertificates[price];
            }
        }
    }
}